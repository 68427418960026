/**
 * Event key names
 */
export enum KeyName {
    enter = "Enter", 
    esc = "Escape|Esc", 
    space = " |Spacebar", 
    leftArrow = "ArrowLeft|Left", 
    upArrow = "ArrowUp|Up", 
    rightArrow = "ArrowRight|Right", 
    downArrow = "ArrowDown|Down", 
    tab = "Tab", 
    shift = "Shift"
}
